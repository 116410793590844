











import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/components/_structures/PageHeader.vue';
import IframeEdools from '@/components/IframeEdools/index.vue';

@Component({
  components: {
    PageHeader,
    IframeEdools,
  },
})
export default class Theme extends Vue {}
